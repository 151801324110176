.nsrl-main{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.nsrl-content{
    height:100%;
    text-align: left;
    margin-left:20px;
    margin-top: 20px;
}
.nsrl-submit{
    padding:50px;
    text-align: center;
}
