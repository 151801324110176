.ms-main{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.ms-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
}
.ms-left{
    flex-grow:2;
}
.ms-right{
    flex-grow:1;
    display: flex;
    flex-direction: column;
}
.ms-left-head{
    display: flex;
    justify-content: space-between;

}
.ms-left-table{
    display: flex;
    justify-content: center;
}
.ms-left-head-left{
    display: flex;
}