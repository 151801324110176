.ft-Content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow:1;
}
.ft-question{
    padding: 10px;
    text-align: left;
}
.ft-menu{
    padding: 10px;
}
.ft-submit{
    padding-top: 20px;
    text-align: center;
}
.first-main{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}
.dropdown{
    padding-bottom: 20px;
}